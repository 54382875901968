import React, { useContext, useEffect, useState } from 'react'
import EachAttendanceRow from '../../Components/Attendance/EachAttendanceRow'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './Attendance.module.css'

const Attendance = () => {
    const authCtx = useContext(AuthContext)
    const [viewMode, setViewMode] = useState('Weekly')
    const toggleViewMode = (k) => setViewMode(k)
    const [attendanceRecords, setAttendanceRecords] = useState([])
    const [isGettingRecords, setIsGettingRecords] = useState(false)


    const getAttendance = async () => {
        setIsGettingRecords(true)
        const getAttendanceResponse = await fetch(BASE_URL_API + "/getAttendance",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    employeeIdentifier: JSON.parse(authCtx?.authRespo).employeeIdentifier,
                    modeType: viewMode
                })
            });

        if (!getAttendanceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAttendanceRespo = await getAttendanceResponse.json();
            if (getAttendanceRespo.status === "success") {
                setAttendanceRecords(getAttendanceRespo.response)
            } else {
                if (getAttendanceRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
            }
            console.log(getAttendanceRespo)
        }
        setIsGettingRecords(false)
    }

    useEffect(() => {
        getAttendance()
    }, [viewMode])

    console.log(attendanceRecords)


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.atTitle}>
                    Attendance Records
                </div>
                <div>
                    <div className={styles.viewMode}>
                        <select onChange={(e) => toggleViewMode(e.target.value)}>
                            <option>Weekly</option>
                            <option>Monthly</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={styles.mainContainer}>
                <div className={styles.contentWrapper}>
                    <div className={styles.contentContainer}>
                        <div className={styles.viewModeInTable}>
                        Attendance - {viewMode}
                        </div>
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.date}>
                                    Date
                                </div>
                                <div className={styles.inTime}>
                                    In-Time
                                </div>
                                <div className={styles.outTime}>
                                    Out-Time
                                </div>
                                <div className={styles.status}>
                                    Status
                                </div>
                                <div className={styles.approvalStatus}>
                                    Approval Status
                                </div>
                                <div className={styles.overtimeHours}>
                                    OT Mins
                                </div>
                                <div className={styles.flagsWrapper}>
                                    Flags
                                </div>
                                <div className={styles.flagsWrapper}>
                                    Actions
                                </div>
                            </div>
                            <div className={styles.tableContainer}>
                                {attendanceRecords?.map((item, index) => (
                                    <EachAttendanceRow key={index} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.summaryWrapper}>
                    <div className={styles.summaryContainer}>
                        <div className={styles.summaryTitle}>Summary</div>
                        <div className={styles.notes}>
                            Attendance syncing is now underway; your previous data will surface in a batch-wise manner following each sync.
                            <div>
                                Please be patient and continue visiting.
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Attendance