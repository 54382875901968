import React, { useState } from 'react'
import StickyHeader from '../../Components/Headers/StickyHeader'
import styles from './DocHome.module.css'

const DocHome = () => {
    const [activePage, setActivePage] = useState('NDA')
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContent}>
                <div className={styles.leftWrapper}>
                    <div className={styles.leftContainer}>
                        <div className={activePage==="NDA" ? styles.eachDocNavActive : styles.eachDocNav}
                            onClick={() => setActivePage('NDA')}
                        >
                            NDA
                        </div>
                        <div className={activePage==="OfferLetter" ? styles.eachDocNavActive : styles.eachDocNav}
                            onClick={() => setActivePage('OfferLetter')}
                        >
                            Offer Letter
                        </div>
                        <div className={activePage==="PromotionalLetter" ? styles.eachDocNavActive : styles.eachDocNav}
                            onClick={() => setActivePage('PromotionalLetter')}
                        >
                            Promotion Letter
                        </div>
                    </div>
                </div>
                <div>

                </div>

            </div>


        </div>
    )
}

export default DocHome