import React from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { MdCake } from 'react-icons/md'
import styles from './EachWorkAnniversaryListView.module.css'

const EachWorkAnniversaryListView = props => {
    const { item } = props
    return (
        <div className={styles.eachComingBirthdays}>
            <div className={styles.upperRowWrapper}>
                <div className={styles.profileUsername}>
                    <div className={styles.userIconWrapper}>
                        <FaUserAlt size={34} />
                    </div>
                    <div className={styles.userDetailsWrapper}>
                        <div className={styles.personName}>
                            {item?.fullname}'s {item?.eventType}
                        </div>
                        <div className={styles.tagsWrapperSmall}>
                            <div className={styles.eachTag}>{item?.gender}</div>
                            <div className={styles.eachTag}>{item?.department}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.birthValueWrapper}>
                    <div className={styles.daysUntilNextBirthday}>
                        {item?.daysUntilEvent}
                    </div>
                    <div className={styles.vsTxt}>
                        Days To <MdCake size={14} />
                    </div>
                </div>
            </div>
            <div>

            </div>

        </div>
    )
}

export default EachWorkAnniversaryListView