import React, { useContext, useEffect, useState } from 'react'
import { validatePAN } from '../../Helper/jarvis-jr'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './ProBankAccountDetails.module.css'

const ProBankAccountDetails = () => {
    const [ifsc, setIfsc] = useState('')
    const [ifscDetails, setIfscDetails] = useState(null)
    const [isSecondInputFocused, setIsSecondInputFocused] = useState(false);
    const [currentlyAdding, setCurrentlyAdding] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [addedBankAccountDetails, setAddedBankAccountDetails] = useState([])

    const [accountNumber, setAccountNumber] = useState('')
    const [reAccountNumber, setReAccountNumber] = useState('')
    const [accountHolderName, setAccountHolderName] = useState('')
    const [panCardNumber, setPanCardNumber] = useState('')

    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)

    const getBankCredentials = async () => {
        const getBankCredentialsResponse = await fetch(BASE_URL_API + "/getBankCredentials",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    organizationIdentifier: authRespo?.organizationIdentifier,
                    employeeIdentifier: authRespo?.employeeIdentifier
                })
            });

        if (!getBankCredentialsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBankCredentialsRespo = await getBankCredentialsResponse.json()
            if (getBankCredentialsRespo.status === "success") {
                setAddedBankAccountDetails(getBankCredentialsRespo.response)
            } else {
                if (getBankCredentialsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAddedBankAccountDetails([])
                }
            }
            console.log(getBankCredentialsRespo)
        }
    }

    useEffect(() => {
        getBankCredentials()
    }, [])




    const ifscHandler = (k) => {
        if (k.length > 14) {
            alert("Please enter a valid IFSC")
        } else {
            setIfsc(k)
        }
    }

    const getIfscDetails = async () => {
        if (ifsc.length > 4) {
            setIsLoading(true)
            const getIfscDetailsResponse = await fetch(`https://ifsc.razorpay.com/${ifsc}`);
            if (!getIfscDetailsResponse.ok) {
                if (getIfscDetailsResponse.status === 404) {
                    alert("Invalid IFSC")
                } else {
                    console.log("Something went wrong : Server Error")
                }
                setIfscDetails(null)
            } else {
                if (getIfscDetailsResponse.status !== 404) {
                    const getIfscDetailsRespo = await getIfscDetailsResponse.json()
                    setIfscDetails(getIfscDetailsRespo)
                    console.log(getIfscDetailsRespo)
                } else {
                    setIfscDetails(null)
                }
            }
            setIsLoading(false)
        } else {
            console.log("Need a valid ifsc")
        }

    }


    const addBankDetailsHandler = async () => {
        if (accountNumber === reAccountNumber && accountNumber !== '') {
            if (accountHolderName.length > 1) {
                if (validatePAN(panCardNumber, accountHolderName)) {
                    const addBankCredentialsResponse = await fetch(BASE_URL_API + "/addBankCredentials",
                        {
                            method: "POST",
                            body: JSON.stringify({
                                token: authCtx.token,
                                organizationIdentifier: authRespo?.organizationIdentifier,
                                employeeIdentifier: authRespo?.employeeIdentifier,
                                ifsc: ifsc,
                                bank: ifscDetails?.BANK,
                                bankAddress: ifscDetails?.ADDRESS,
                                branch: ifscDetails?.BRANCH,
                                city: ifscDetails?.CITY,
                                district: ifscDetails?.DISTRICT,
                                state: ifscDetails?.STATE,
                                accountNumber: accountNumber,
                                accountHolderName: accountHolderName,
                                pancardNumber: panCardNumber
                            })
                        });

                    if (!addBankCredentialsResponse.ok) {
                        console.log("Something went wrong : Server Error")
                    } else {
                        const addBankCredentialsRespo = await addBankCredentialsResponse.json()
                        if (addBankCredentialsRespo.status === "success") {
                            setCurrentlyAdding(false)
                            getBankCredentials()
                        } else {
                            if (addBankCredentialsRespo.message === "token Expired, please login again!") {
                                authCtx.logout()
                            } else {
                                console.log(addBankCredentialsRespo)
                            }
                        }
                    }

                } else {
                    alert("Please enter a valid pan card number")
                }
            } else {
                alert("Please enter account holder name!")
            }
        } else {
            alert("Account number doesn't match!")
        }
    }

    useEffect(() => {
        setIfscDetails(null)
    }, [ifsc])



    return (
        <div>
            {currentlyAdding === false ?
                <div className={styles.credentialsWrapper}>
                    <div className={styles.credentialsHeader}>
                        <button onClick={() => setCurrentlyAdding(true)}>Add A Bank Account Details</button>
                    </div>
                    <div className={styles.credentialsBody}>

                        {addedBankAccountDetails?.map((item, index) => (
                            <div key={index} className={styles.eachBankCredential}>
                                <div className={styles.bank}>
                                    {item?.bank}
                                </div>
                                <div className={styles.bankAddress}>
                                    {item?.bankAddress}
                                </div>
                                <div className={styles.accountHolderName}>
                                    {item?.accountHolderName}
                                </div>
                                <div className={styles.accountNumber}>
                                    {item?.accountNumber}
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
                :
                <div className={styles.formWrapper}>
                    <div className={styles.ifscContainer}>
                        <div className={styles.fieldCaption}>IFSC</div>
                        <div className={styles.ifscContent}>
                            <div className={styles.ifscWrapper}>
                                <input placeholder="Enter IFSC code" value={ifsc} className={styles.ifsc} onChange={(e) => ifscHandler(e.target.value)} />
                            </div>
                            <div className={styles.getBtnWrapper}>
                                {isLoading === false ?
                                    ifscDetails !== null ?
                                        <button className={styles.validated}>
                                            Validated
                                        </button>
                                        :
                                        <button onClick={() => getIfscDetails()}>
                                            Validate
                                        </button>
                                    :
                                    <button >
                                        Validating...
                                    </button>
                                }
                            </div>
                        </div>

                        {ifscDetails !== null &&
                            <div>
                                <div className={styles.bankNameWrapper}>
                                    <div className={styles.fieldCaption}>Bank Name</div>
                                    <input placeholder="Bank Name" readOnly value={ifscDetails?.BANK} />
                                </div>
                                <div className={styles.bankAddress}>
                                    <div className={styles.fieldCaption}>Address</div>
                                    <textarea placeholder="Address" readOnly value={ifscDetails?.ADDRESS} />
                                </div>

                                <div className={styles.twoInARow}>
                                    <div className={styles.eachField}>
                                        <div className={styles.fieldCaption}>Branch</div>
                                        <input placeholder="Branch" readOnly value={ifscDetails?.BRANCH} />
                                    </div>
                                    <div className={styles.eachField}>
                                        <div className={styles.fieldCaption}>City</div>
                                        <input placeholder="City" readOnly value={ifscDetails?.CITY} />
                                    </div>
                                </div>

                                <div className={styles.twoInARow}>
                                    <div className={styles.eachField}>
                                        <div className={styles.fieldCaption}>District</div>
                                        <input placeholder="District" readOnly value={ifscDetails?.DISTRICT} />
                                    </div>
                                    <div className={styles.eachField}>
                                        <div className={styles.fieldCaption}>State</div>
                                        <input placeholder="State" readOnly value={ifscDetails?.STATE} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {ifscDetails !== null &&
                        <div className={styles.formContainer}>
                            <div className={styles.bankAccountNumberWrapper}>
                                <div className={styles.fieldCaption} id="bankDetails">Enter Bank Account Number</div>
                                <input type={isSecondInputFocused === true ? `password` : `text`}
                                    onFocus={() => setIsSecondInputFocused(false)}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                    value={accountNumber}
                                />
                            </div>

                            <div className={styles.bankAccountNumberWrapper}>
                                <div className={styles.fieldCaption} >Re-Enter Bank Account Number</div>
                                <input onFocus={() => setIsSecondInputFocused(true)}
                                    onChange={(e) => setReAccountNumber(e.target.value)}
                                    value={reAccountNumber}
                                />
                            </div>

                            <div className={styles.accountHolderName}>
                                <div className={styles.fieldCaption} >Account Holder Name</div>
                                <input onChange={(e) => setAccountHolderName(e.target.value)}
                                    value={accountHolderName}
                                />
                            </div>

                            <div className={styles.pancardWrapper}>
                                <div className={styles.fieldCaption} >Pan Card Number</div>
                                <input onChange={(e) => setPanCardNumber(e.target.value)}
                                    value={panCardNumber}
                                />
                            </div>

                            <div className={styles.actionWrapper}>
                                <button className={styles.cancelBtn} onClick={() => setCurrentlyAdding(false)}>
                                    Cancel
                                </button>
                                <button className={styles.submitBtn} onClick={() => addBankDetailsHandler()} >Submit</button>
                            </div>
                        </div>
                    }

                </div>
            }

        </div>
    )
}

export default ProBankAccountDetails