import React from 'react'
import StickyHeader from '../../Components/Headers/StickyHeader'

const CHome = () => {
    return (
        <div>
            CHome

        </div>
    )
}

export default CHome