import React from 'react'
import styles from './EachAnnouncements.module.css'

const EachAnnouncements = (props) => {
    const { item } = props
    return (
        <div className={styles.mainWrapper}>
            <div>
                <div>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/Scuti/gifs/new-giphy.gif" className={styles.newTag} />
                </div>

                <div>
                    {item?.title}
                </div>

                <div className={styles.announcement}>
                    {item?.announcement}
                </div>
            </div>
        </div>
    )
}

export default EachAnnouncements