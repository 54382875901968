import React, { useContext, useEffect, useState } from 'react'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './AllAnnouncements.module.css'
import EachAnnouncements from './EachAnnouncements'

const AllAnnouncements = () => {
    const [announcements, setAnnouncements] = useState([1])

    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)

    const getAllAnnoucements = async () => {
        const getAllAnnoucementsResponse = await fetch(BASE_URL_API+"/getAllAnnoucements",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                organizationIdentifier : authRespo?.organizationIdentifier,
                employeeIdentifier: authRespo?.employeeIdentifier
            })
        });

        if(!getAllAnnoucementsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllAnnoucementsRespo = await getAllAnnoucementsResponse.json()
            if(getAllAnnoucementsRespo.status==="success") {
                setAnnouncements(getAllAnnoucementsRespo.response)
            } else {
                if(getAllAnnoucementsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllAnnoucementsRespo)
        }
    }

    useEffect(() => {
        getAllAnnoucements()
    }, [])
    

    return (
        <div className={styles.noticesWrapper}>
            <div className={styles.annoucementTitle}><HiOutlineSpeakerphone size={28} />Announcements</div>
            <div>
                All announcements will appear here!
            </div>
            <div>
                {announcements?.map((item, index) => (
                    <EachAnnouncements key={index} item={item} />
                ))}
            </div>
        </div>
    )
}

export default AllAnnouncements