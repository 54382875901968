import React, { useState } from "react";

const AuthContext = React.createContext({
    token: localStorage.getItem("token"),
    isLoggedIn: false,
    authRespo: localStorage.getItem("authRespo"),
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [authRespo, setAuthRespo] = useState(localStorage.getItem('authRespo'));

    const userIsLoggedIn = !!token;

    const loginHandler = (token, authRespo) => {
        localStorage.setItem("token", token);
        localStorage.setItem("authRespo", authRespo);
        setToken(token);
        setAuthRespo(authRespo)
    }

    const logoutHandler = () => {
        setToken(null);
        setAuthRespo(null)
        localStorage.removeItem("token");
        localStorage.removeItem("authRespo");
    }

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        authRespo: authRespo,
        login: loginHandler,
        logout: logoutHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;