import React, { useContext, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import AuthContext from '../Store/auth-context'
import styles from './Login.module.css'

import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BASE_URL_API } from '../References/Urls';



const Login = () => {
    const authCtx = useContext(AuthContext)
    const [passRequired, setPassRequired] = useState(false)
    const [hasRememberedChecked, setHasRememberedChecked] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isValidatingUsername, setIsValidatingUsername] = useState(false)
    const [authenticating, setAuthenticating] = useState(false)

    const validateUsername = async () => {
        setIsValidatingUsername(true)
        const validateUsernameResponse = await fetch(BASE_URL_API + "/validateUsername",
            {
                method: "POST",
                body: JSON.stringify({
                    username: username
                })
            });

        if (!validateUsernameResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const validateUsernameRespo = await validateUsernameResponse.json()
            if (validateUsernameRespo.status === "success") {
                setPassRequired(true)
            } else {
                setPassRequired(false)
            }
        }
        setIsValidatingUsername(false)
    }


    const loginNow = async () => {
        setAuthenticating(true)
        const authResponse = await fetch(BASE_URL_API + "/auth",
            {
                method: "POST",
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });

        if (!authResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const authRespo = await authResponse.json()
            if (authRespo.status === "success") {
                authCtx.login(authRespo?.authRespo?.authToken, JSON.stringify(authRespo?.authRespo))
            } else {

            }
            console.log(authRespo)
        }
        setAuthenticating(false)
        // authCtx.login("okay", "sys")
    }


    return (
        <div className={styles.mainWrapper} >
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.logoWrapper}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/Scuti/logos/scuti.png" />
                    </div>
                    <div className={styles.loginTxt}>
                        Login to Scuti.pro
                    </div>
                    <div>
                        <div className={passRequired === true ? styles.eachFieldWrapperOpen : styles.eachFieldWrapper}>
                            <input placeholder="Enter your username / e-mail" value={username} autoFocus={true} onChange={(e) => setUsername(e.target.value)} onFocus={() => setPassRequired(false)} />
                        </div>
                        {passRequired === true &&
                            <div className={styles.passwordWrapper}>
                                <input placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} autoFocus={true} type={isPasswordVisible === true ? `text` : `password`} />
                                <div>
                                    {isPasswordVisible === true ?
                                        <div onClick={() => setIsPasswordVisible(!isPasswordVisible)} className={styles.eyeIcon} >
                                            <AiFillEye size={22} color="#ffffff" />
                                        </div>
                                        :
                                        <div onClick={() => setIsPasswordVisible(!isPasswordVisible)} className={styles.eyeIcon} >
                                            <AiFillEyeInvisible size={22} color="#ffffff" />
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {passRequired === false && (
                            isValidatingUsername === true ?
                                <div className={styles.loginBtnWrapperValidating}>
                                    <button>Validating Username...</button>
                                </div>
                                :
                                <div className={styles.loginBtnWrapper}>
                                    <button onClick={() => validateUsername()}>Next</button>
                                </div>)
                        }
                        {passRequired === true &&
                            <div className={styles.loginBtnWrapper}>
                                {authenticating === true ?
                                    <button>Logging In...</button>
                                    :
                                    <button onClick={() => loginNow()}>Login</button>
                                }
                            </div>
                        }
                    </div>
                    <div className={styles.rememberMeWrapper} onClick={() => setHasRememberedChecked(!hasRememberedChecked)}>
                        <div className={styles.rememberMeIcon}>
                            {hasRememberedChecked === false ?
                                <MdOutlineCheckBoxOutlineBlank size={24} />
                                :
                                <MdOutlineCheckBox size={24} />
                            }
                        </div>
                        <div className={styles.rememberMeTxt}>
                            Remember Me
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login