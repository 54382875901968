import React from 'react'
import styles from './EachBirthday.module.css'
import EachBirthdayListView from './EachBirthdayListView'
import EachBirthdayWishView from './EachBirthdayWishView'

const EachBirthday = (props) => {
    const { item } = props;
    return (
        <div>
            {item?.daysUntilEvent === "0" ?
                <EachBirthdayWishView item={item} />
                :
                <EachBirthdayListView item={item} />
            }
        </div>
    )
}

export default EachBirthday