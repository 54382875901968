export const getHrsMinsFromDate = (dateString) => {
  // Try-catch block to handle potential parsing errors
  try {
    // Create a Date object from the string
    const date = new Date(dateString);

    // Check if the date is valid (i.e., not "Invalid Date")
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string provided');
    }

    // Get hours (0-23) and minutes (0-59)
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format hours with leading zero if needed
    const formattedHours = hours.toString().padStart(2, '0');

    // Format minutes with leading zero if needed
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Return an object with hours and minutes
    return {
      hours: formattedHours,
      minutes: formattedMinutes,
    };
  } catch (error) {
    console.error('Error parsing date string:', error.message);
    // You can return a default value or throw a new error here
    return { hours: 'Invalid', minutes: 'Invalid' };
  }
};




export const validatePAN = (panNumber, fullName) => {
  // Check for empty or invalid inputs
  if (!panNumber || !fullName || typeof panNumber !== 'string' || typeof fullName !== 'string') {
    console.log("Invalid input: PAN number and full name must be non-empty strings.");
    return false;
  }

  // Trim white spaces from both sides of the strings
  panNumber = panNumber.trim();
  fullName = fullName.trim();
  
  // Convert PAN to uppercase for case-insensitive comparison
  panNumber = panNumber.toUpperCase();

  // Check PAN length and format
  if (panNumber.length !== 10 || !panNumber.match(/^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/)) {
    console.log(`Invalid PAN format: PAN should be in the format ABCTY1234D.${panNumber}`);
    return false;
  }


  // Check status code for individual
  if (panNumber.charAt(3) !== 'P') {
    console.log("Invalid status code: PAN holder status should be 'P' for individual.");
    return false;
  }

  // Extract and handle last name initial (considering special characters)
  const lastName = fullName.split(/\s+/).pop().toUpperCase(); // Get last name, uppercase
  const lastNameInitial = lastName.charAt(0).replace(/[^A-Z]/g, ''); // Get first char, remove non-alphabetic

  // Check if last name initial matches and is not empty due to special characters
  if (!lastNameInitial) {
    console.log("Invalid last name: Last name must contain alphabetic characters.");
    return false;
  }

  if (lastNameInitial !== panNumber.charAt(4)) {
    console.log("Last name initial doesn't match: Last name initial in PAN doesn't match with the provided name.");
    return false;
  }

  return true;
}

