import React, { useContext, useEffect, useState } from 'react'
import StickyHeader from '../Components/Headers/StickyHeader'
import styles from './Home.module.css'
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { BASE_URL_API } from '../References/Urls';
import AuthContext from '../Store/auth-context';
import Birthdays from '../Components/Events/Birthdays';
import ClockIn from '../Components/ClockIn/ClockIn';
import AllAnnouncements from '../Components/Announcements/AllAnnouncements';
import AllEventsAtHome from '../Components/Events/AllEventsAtHome';


const Home = () => {
    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)
    const [isClockOpen, setIsClockOpen] = useState(false)
    const clockToggle = () => setIsClockOpen(false)

    


    return (
        <div className={styles.homeWrapper}>
            <div className={styles.bodyWrapper}>
                <div className={styles.leftWrapper}>
                    <div className={styles.eachCardWrapper}>
                        <div className={styles.markTimeWrapper}>
                            <div className={styles.smallTxt}>
                                Mark your In-time.
                            </div>
                            <div className={styles.markBtnWrapper}>
                                <button onClick={() => setIsClockOpen(true)}>Clock In</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.centerWrapper}>
                    <ClockIn isOpen={isClockOpen} clockToggle={clockToggle} />
                    <AllEventsAtHome />
                    <div>
                        {/* apple */}
                    </div>
                    <div className={styles.tempGap}>
                        {/* grapes! */}
                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <AllAnnouncements />
                </div>
            </div>
        </div>
    )
}

export default Home