import React, { useContext, useEffect, useState } from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { MdCake } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './Birthdays.module.css'

const Birthdays = () => {
    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)

    const [birthdays, setBirthdays] = useState([])

    const getBirthdays = async () => {
        const getBirthdaysResponse = await fetch(BASE_URL_API + "/getBirthdays",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    organizationIdentifier: authRespo.organizationIdentifier
                })
            });

        if (!getBirthdaysResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBirthdaysRespo = await getBirthdaysResponse.json();
            if (getBirthdaysRespo.status === "success") {
                setBirthdays(getBirthdaysRespo.response)
            } else {
                if (getBirthdaysRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setBirthdays([])
                }
            }
            console.log(getBirthdaysRespo)
        }
    }

    useEffect(() => {
        getBirthdays()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                Upcoming Birthdays
            </div>
            <div>
                {birthdays?.slice(0, 6)?.map((item, index) => (
                    <div key={index}>
                        {item?.daysUntilEvent === "0" ?
                            <div className={styles.birthdayTodayWrapper}>
                                <div className={styles.btTitle}>
                                    {item?.eventType} Today
                                </div>
                                <div>
                                    {/* <div>{item.employeeIdentifier}</div> */}
                                    <Link to={`/people/${item?.employeeIdentifier}`} className={styles.heroname}>
                                        <div>
                                            {item.fullname}
                                        </div>
                                        <div className={styles.tagsWrapper}>
                                            <div className={styles.eachTag}>{item?.gender}</div>
                                            <div className={styles.eachTag}>{item?.department}</div>
                                        </div>
                                    </Link>
                                    {/* <div>{item?.daysUntilNextBirthday}</div> */}
                                </div>
                                <div className={styles.writeOnTimeline}>
                                    <textarea placeholder={`Write something about ${item?.fullname} on their ${item?.eventType}`}>

                                    </textarea>
                                </div>
                            </div>
                            :
                            <div className={styles.eachComingBirthdays}>
                                <div className={styles.upperRowWrapper}>
                                    <div className={styles.profileUsername}>
                                        <div className={styles.userIconWrapper}>
                                            <FaUserAlt size={34} />
                                        </div>
                                        <div className={styles.userDetailsWrapper}>
                                            <div className={styles.personName}>
                                                {item?.fullname}
                                            </div>
                                            <div className={styles.tagsWrapperSmall}>
                                                <div className={styles.eachTag}>{item?.gender}</div>
                                                <div className={styles.eachTag}>{item?.department}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.birthValueWrapper}>
                                        {/* <div className={styles.vsTxt}>Birthday in next</div> */}
                                        <div className={styles.daysUntilNextBirthday}>
                                            {item?.daysUntilEvent}
                                        </div>
                                        <div className={styles.vsTxt}>
                                            Days To <MdCake size={14} />
                                        </div>
                                    </div>
                                </div>
                                <div>

                                </div>

                            </div>
                        }
                    </div>
                ))}
            </div>
            <div className={styles.birthdaysFooter}>
                <Link to={`/birthdays`}>
                    See All
                </Link>
            </div>
        </div>
    )
}

export default Birthdays