import React, { useState } from 'react'
import StickyHeader from '../../Components/Headers/StickyHeader'
import styles from './EHome.module.css'
import ETableRowEach from './ETableRowEach'


const EHome = () => {
    const [earningsData, setEarningsData] = useState([1, 2,])
    return (
        <div>
            <div className={styles.mainHeader}>
                <div>-</div>
                <div>
                    <select>
                        <option>Current Month</option>
                        <option>Last Month</option>
                        <option>Current Year</option>
                        <option>Last Year</option>
                        <option>Current Financial Year</option>
                        <option>Last Financial Year</option>
                    </select>
                </div>
            </div>

            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <div>
                            Period
                        </div>
                        <div>

                        </div>
                        <div>

                        </div>
                        <div>
                            Action
                        </div>
                    </div>
                    <div className={styles.tableBodyWrapper}>
                        {earningsData?.map((item, index) => (
                            <ETableRowEach item={item} key={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EHome