import React from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { MdCake } from 'react-icons/md'
import { Link } from 'react-router-dom'
import styles from './EachBirthdayWishView.module.css'

const EachBirthdayWishView = (props) => {
    const { item } = props
    return (
        <div className={styles.birthdayTodayWrapper}>
            <div className={styles.btTitle}>
                {item?.eventType} Today
            </div>
            <div>
                {/* <div>{item.employeeIdentifier}</div> */}
                <Link to={`/people/events/${item?.employeeIdentifier}`} className={styles.heroname}>
                    <div>
                        {item.fullname}
                    </div>
                    <div className={styles.tagsWrapper}>
                        <div className={styles.eachTag}>{item?.gender}</div>
                        <div className={styles.eachTag}>{item?.department}</div>
                    </div>
                </Link>
                {/* <div>{item?.daysUntilNextBirthday}</div> */}
            </div>
            <Link to={`/people/events/${item?.employeeIdentifier}`} className={styles.writeOnTimeline}>
                <textarea placeholder={`Write something about ${item?.fullname} on their ${item?.eventType}`}>

                </textarea>
            </Link>
        </div>
    )
}

export default EachBirthdayWishView