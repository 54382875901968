import React from 'react'
import { Link } from 'react-router-dom'

const ETableRowEach = (props) => {
    const { item } = props
    return (
        <div>
            <div>

            </div>
            <div>

            </div>
            <div>
                <Link to={`/earnings/2023/01`}>View Salary Slip</Link>
            </div>
        </div>
    )
}

export default ETableRowEach