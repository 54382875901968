import React from 'react'
import styles from './EachWorkAnniversary.module.css'
import EachWorkAnniversaryListView from './EachWorkAnniversaryListView'
import EachWorkAnniversaryWishView from './EachWorkAnniversaryWishView'

const EachWorkAnniversary = (props) => {
    const { item } = props
    return (
        <div>
            {item?.daysUntilEvent === "0" ?
                <EachWorkAnniversaryWishView item={item} />
                :
                <EachWorkAnniversaryListView item={item}/>
            }
        </div>
    )
}

export default EachWorkAnniversary