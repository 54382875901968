import React, { useEffect, useState } from 'react'
import styles from './StickyHeader.module.css'

import { FaCalendar, FaHome, FaMoneyBill, FaRegListAlt, FaRupeeSign, FaSortAmountUp, FaUserAlt } from "react-icons/fa";

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';


const StickyHeader = () => {
    const location = useLocation()
    const [pathName, setPathName] = useState('/home')
    useEffect(() => {
        setPathName(location?.pathname)
    }, [location])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftWrapper}>
                <Link to="/" className={styles.logoWrapper}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/Scuti/logos/scuti.png" />
                </Link>
            </div>
            <div className={styles.centerWrapper}>
                <Link to={`/home`} className={pathName === "/home" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                    <div className={styles.navIcon}>
                        <FaHome size={18} color={pathName === "/home" ? "#343A40" : "#DFE2E6"} />
                    </div>
                    <div className={styles.navTxt}>
                        Home
                    </div>
                </Link>
                <Link to={`/calendar`} className={pathName === "/calendar" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                    <div className={styles.navIcon}>
                        <FaCalendar size={18} color={pathName === "/calendar" ? "#343A40" : "#DFE2E6"} />
                    </div>
                    <div className={styles.navTxt}>
                        Calendar
                    </div>
                </Link>
                <Link to={`/earnings`} className={pathName === "/earnings" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                    <div className={styles.navIcon}>
                        <FaRupeeSign size={18} color={pathName === "/earnings" ? "#343A40" : "#DFE2E6"} />
                    </div>
                    <div className={styles.navTxt}>
                        Earnings
                    </div>
                </Link>
                <Link to={`/documents`} className={pathName === "/documents" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                    <div className={styles.navIcon}>
                        <FaRegListAlt size={18} color={pathName === "/documents" ? "#343A40" : "#DFE2E6"} />
                    </div>
                    <div className={styles.navTxt}>
                        Documents
                    </div>
                </Link>

            </div>
            <div className={styles.rightWrapper}>
                <Link to={`/profile`} className={pathName === "/profile" ? styles.eachNavBtnActive : styles.eachNavBtn}>
                    <FaUserAlt />
                </Link>
            </div>
        </div>
    )
}

export default StickyHeader