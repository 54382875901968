import React, { useState } from 'react'
import ClockIn from '../ClockIn/ClockIn'
import styles from './EachAttendanceRow.module.css'

const EachAttendanceRow = props => {
    const { item } = props
    const [isClockOpen, setIsClockOpen] = useState(false)

    const clockInOutHandler = () => {
        setIsClockOpen(true)
    }

    console.log(item)

    const clockToggle = () => setIsClockOpen(false)

    return (
        <div className={styles.eachRow}>
            <ClockIn isOpen={isClockOpen} clockToggle={clockToggle} attendanceDate={item?.attendanceDate?.substring(0, 10)} />
            <div className={styles.date}>
                {item?.attendanceDate?.substring(0, 10)}
            </div>
            <div className={styles.inTime}>
                {item?.inTime?.substring(11, 16)}
            </div>
            <div className={styles.outTime}>
                {item?.outTime?.substring(11, 16)}
            </div>
            <div className={styles.status}>
                {item?.attendanceStatus!=='' ? item?.attendanceStatus : "-"}
            </div>
            <div className={styles.approvalStatus}>
                {item?.approvalStatus}
            </div>
            <div className={styles.overtimeHours}>
                {item?.overTimeMinutes!==`` ? `${item?.overTimeMinutes} minutes` : ``} 
            </div>
            <div className={styles.flagsWrapper}>
                -
            </div>
            <div className={styles.actionsWrapper}>
                <button onClick={() => clockInOutHandler()}>Clock-In/Out</button>
            </div>

        </div>
    )
}

export default EachAttendanceRow