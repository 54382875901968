import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/Urls'
import AuthContext from '../../Store/auth-context'
import styles from './AllEventsAtHome.module.css'
import EachBirthday from './Birthdays/EachBirthday'
import EachWorkAnniversary from './WorkAnniversaries/EachWorkAnniversary'

const AllEventsAtHome = () => {
    const authCtx = useContext(AuthContext)
    const authRespo = JSON.parse(authCtx.authRespo)

    const [allEvents, setAllEvents] = useState([])


    const getEvents = async () => {
        const getEventsResponse = await fetch(BASE_URL_API + "/getEvents",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    organizationIdentifier: authRespo?.organizationIdentifier
                })
            });

        if (!getEventsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEventsRespo = await getEventsResponse.json();
            if (getEventsRespo.status === "success") {
                setAllEvents(getEventsRespo.response)
            } else {
                if (getEventsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllEvents([])
                }
            }
            console.log(getEventsRespo)
        }
    }

    useEffect(() => {
        getEvents()
    }, [])

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                Upcoming Events
            </div>
            {allEvents?.slice(0, 12)?.map((item, index) => (
                <div key={index}>
                    {item?.eventType==="Birthday" && <EachBirthday item={item} />}
                    {item?.eventType==="Work Anniversary" && <EachWorkAnniversary item={item} />}
                </div>
            ))}
        </div>
    )
}

export default AllEventsAtHome