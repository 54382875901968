import React from 'react'
import Birthdays from '../../Components/Events/Birthdays'
import styles from './AllBirthdays.module.css'

const AllBirthdays = () => {
    return (
        <div>
            <Birthdays />
        </div>
    )
}

export default AllBirthdays